// eslint-disable-next-line consistent-return
document.addEventListener('click', (e) => {
  if (e.target.closest('.icon-menu')) {
    document.documentElement.classList.toggle('menu-open');
  }

  if (e.target.closest('[data-target]')) {
    if (document.documentElement.classList.contains('menu-open')) {
      document.documentElement.classList.remove('menu-open');
    }

    const { target } = e.target.closest('[data-target]').dataset;
    const targetEl = document.querySelector(target);

    if (!targetEl) return false;

    window.scrollTo({
      top: targetEl.offsetTop - 30,
      behavior: 'smooth',
    });

    e.preventDefault();
  }
});
